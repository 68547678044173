export const menu = [
  {
    headTitle1: "เมนูของข้า",
    headTitle2: "Dashboards,Widgets & Layout.",
    type: "headtitle",
  },
  {
    title: "Authentication",
    type: "sub",
    active: false,
    children: [
      {
        path: "/auth/login",
        title: "Login Simple",
        type: "link",
        active: false,
      },
    ],
  },
  {
    title: "Ecommerce",
    icon: "stroke-ecommerce",
    icon1: "fill-ecommerce",
    type: "sub",
    active: false,
    isPinned: false,
    children: [
      {
        path: "/ecommerce/invoice",
        title: "Invoices",
        type: "sub",
        children: [
          {
            path: "/ecommerce/invoice_1",
            title: "Invoice-1",
            type: "link",
          },
          {
            path: "/ecommerce/invoice_2",
            title: "Invoice-2",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard_default",
    title: "Dashboard",
    icon: "stroke-file",
    icon1: "fill-file",
    type: "link",
    isPinned: false,
    active: false,
  },
  {
    title: "Dashboards",
    icon: "stroke-home",
    icon1: "fill-home",
    type: "sub",
    badgeType: "light-primary",
    active: false,
    isPinned: false,
    children: [
      {
        path: "/dashboards/dashboard_default",
        menuFocus: ["Dashboards"],
        title: "Default",
        type: "link",
      },
      {
        path: "/dashboards/dashboard_project",
        title: "project",
        type: "link",
      },
      {
        path: "/dashboards/dashboard_ecommerce",
        title: "Ecommerce",
        type: "link",
      },
      {
        path: "/dashboards/dashboard_education",
        title: "Education",
        type: "link",
      },
    ],
  },
];
