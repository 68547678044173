<template>
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="login-card login-dark">
          <div>
            <div>
              <router-link class="logo" to="/"><img class="img-fluid for-light"
                                                    src="@/assets/images/logo/logo.png" alt="looginpage"><img
                class="img-fluid for-dark"
                src="@/assets/images/logo/logo_dark.png" alt="looginpage"></router-link>
            </div>
            <div class="login-main">
              <form class="theme-form" @submit.prevent="">
                <h4>Sign in to account</h4>
                <p>Enter your username & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input v-model="username" class="form-control" type="text" placeholder="Test@gmail.com">
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input v-model="password" :type="type" class="form-control" name="login[password]"
                           placeholder="*********">
                    <div class="show-hide"><span class="show" @click="showPassword"> </span></div>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="text-end mt-3">
                    <button class="btn btn-primary btn-block w-100" type="submit" @click="doLogin">Sign
                      in
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";


let router = useRouter();
let type = ref<string>("password");
let username = ref<string>("username");
let password = ref<string>("test@123456");

const authStore = useAuthStore();

function showPassword() {
  if (type.value === "password") {
    type.value = "text";
  } else {
    type.value = "password";
  }
}

async function doLogin() {

  console.log(process.env.VUE_APP_W3PF_BASEURL);

  // fetch(process.env.VUE_APP_W3PF_BASEURL + ``, {
  //   method: "POST",
  //   body: JSON.stringify({
  //     title: "foo",
  //     body: "bar",
  //     userId: 1,
  //   }),
  //   headers: {
  //     "Content-type": "application/json; charset=UTF-8",
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((json) => console.log(json));


  try {
    const rawResponse = await fetch(process.env.VUE_APP_W3PF_BASEURL + `/web/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        username: username.value,
        password: password.value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const response = await rawResponse.json();
    console.log(response);

    if (response.code != 0) {
      toast.error(response.msg.en + ` [${response.msg.th}]`, { position: "top-right", autoClose: 4000 });
      return;
    }

    router.replace("/dashboard_default");
    authStore.login(username.value);
    localStorage.setItem("user", username.value);
    localStorage.setItem("SidebarType", "compact-wrapper");

  } catch (error) {
    console.error(error);
    toast.error("Opps... Please Try Again", { position: "top-right", autoClose: 2000 });
  }
  return;
}
</script>