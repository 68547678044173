import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import BodyView from "@/layout/BodyView.vue";
import indexDashboard from "@/pages/indexDashboard.vue";

import AuthView from "@/components/common/block/auth/AuthView.vue";
import LoginPage from "@/components/common/block/auth/LoginPage.vue";

import indexErrorPage4 from "@/pages/error/indexErrorPage4.vue";

import { useAuthStore } from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: BodyView,
  //   meta: {
  //     title: "Default | Mofi - Premium Admin Template",
  //   },
  //   children: [
  //     {
  //       path: "",
  //       name: "defaultRoot",
  //       component: indexDashboard,
  //       meta: {
  //         title: "Default | Mofi - Premium Admin Template",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/",
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      // return {path: '/search', query: {q: to.params.searchText}}
      return { path: "/auth/login" };
    },
  },
  {
    path: "/dashboard_default",
    name: "dashboard_default",
    component: BodyView,
    children: [
      {
        path: "",
        name: "dashboard_default",
        component: indexDashboard,
        meta: {
          requiresAuth: true,
          title: "Dashboards | Mofi - Premium Vue Admin Template",
          menuFocus: ["Dashboard"],
        },
      },
    ],
  },
  {
    path: "/dashboards",
    component: BodyView,
    children: [
      {
        path: "dashboard_default",
        name: "default",
        component: indexDashboard,
        meta: {
          requiresAuth: true,
          title: "Dashboards | Mofi - Premium Vue Admin Template",
          menuFocus: ["Dashboards"],
        },
      },
    ],
  },
  {
    path: "/auth",
    component: AuthView,
    children: [
      {
        path: "login",
        name: "login",
        component: LoginPage,
        meta: {
          title: "Mofi - Premium Vue Admin Template",
        },
      },
    ],
  },
  {
    path: "/ecommerce",
    component: BodyView,
    children: [
      {
        path: "invoice_1",
        name: "invoice_1",
        component: indexDashboard,
        meta: {
          title: "Dashboards | Mofi - Premium Vue Admin Template",
          menuFocus: ["Ecommerce", "Invoices"],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*", component: indexErrorPage4,
    meta: {
      title: "404 Not Found | Mofi - Premium Vue Admin Template",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to) => {
  const auth = useAuthStore();

  if (typeof to.meta.title === "string") {
    document.title = to.meta.title;
  }
  if (to.path === "/auth/login" && auth.isLoggedIn()) {
    return {
      path: "/dashboard_default",
    };
  }
  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
    return {
      path: "auth/login",
      query: { redirect: to.fullPath },
    };
  }
});

export default router;
