import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useAuthStore = defineStore("auth", () => {
  const isAuthenticated = ref<boolean>(false);
  const user = ref();
  const JWT = ref<string | null>(null);
  const JWT_AGENT_ACCESS_TOKEN = "JWT_AGENT_ACCESS_TOKEN";

  function login(jwt: string) {
    sessionStorage.setItem(JWT_AGENT_ACCESS_TOKEN, jwt);
    isAuthenticated.value = true;
    JWT.value = jwt;
  }

  function logout() {
    sessionStorage.removeItem(JWT_AGENT_ACCESS_TOKEN);
    isAuthenticated.value = false;
    JWT.value = null;
  }

  function pageMounted() {
    console.log("pageMounted()====");
  }

  function isLoggedIn() {
    const jwt = sessionStorage.getItem(JWT_AGENT_ACCESS_TOKEN);
    console.log("isLoggedIn() ======");
    console.log(jwt);
    if (!jwt) {
      return false;
    }
    login(jwt);
    return true;
  }

  return {
    login,
    logout,
    isLoggedIn,
    pageMounted,
  };
});